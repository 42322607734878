import { defineStore } from "pinia";

export const useModal = defineStore("modal", {
  state: () => ({
    headline: null,
    text: null,
    callback: null,
    cancelCallback: null,
    isOpen: false,
  }),

  actions: {
    ask(modal) {
      this.headline = modal.headline;

      this.text = modal.text;

      this.callback = modal.callback;

      this.cancelCallback = modal.cancelCallback || null;

      this.open();
    },

    confirm() {
      this.callback();

      this.close();
    },

    cancel() {
      if (this.cancelCallback) {
        this.cancelCallback();
      }

      this.close();
    },

    open() {
      this.isOpen = true;
    },

    close() {
      this.isOpen = false;
    },
  },
});
